import React, { useState } from "react";
import { Container } from "@mui/material";
import DeltaTable from "./DeltaTable";
import DeltaForm from "./DeltaForm";
import ConfirmDialog from "./ConfirmDialog";
import { useSelector } from "react-redux";

export default function Delta({ setSnackbarProps }) {
  const [deltaFormOpen, setDeltaFormOpen] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteID, setConfirmDeleteID] = useState(null);

  const initialValues = {
    lowerLimit: 0,
    upperLimit: 0,
    color: "#ffffff",
    keyword: "",
  };
  const buckets = useSelector((state) => state.chartBuckets.deltaBuckets);
  const [currentBin, setCurrentBin] = useState(null);

  const isValidRange = (values, isEditing) => {
    const from = parseFloat(values.lowerLimit);
    const to =
      values.upperLimit === null ? null : parseFloat(values.upperLimit);

    if (to !== null && to < from) {
      return false;
    }

    let flag = true;
    let filteredBuckets;

    if (isEditing) {
      filteredBuckets = buckets.filter((bucket) => {
        return currentBin.lowerLimit !== bucket.lowerLimit;
      });
    } else {
      filteredBuckets = buckets;
    }

    for (let bucket of filteredBuckets) {
      if (to !== null) {
        if (bucket.upperLimit !== null) {
          if (
            parseFloat(bucket.lowerLimit) <= from &&
            from < parseFloat(bucket.upperLimit)
          ) {
            flag = false;
            break;
          }

          if (
            parseFloat(bucket.lowerLimit) < to &&
            to <= parseFloat(bucket.upperLimit)
          ) {
            flag = false;
            break;
          }
        } else {
          if (
            !isEditing &&
            bucket.lowerLimit >= from &&
            to >= bucket.lowerLimit
          ) {
            flag = false;
            break;
          }
        }
      } else {
        if (
          from <= parseFloat(bucket.lowerLimit) &&
          from <= parseFloat(bucket.upperLimit)
        ) {
          flag = false;
          break;
        }

        if (bucket.upperLimit === null) {
          flag = false;
          break;
        }
      }
    }

    return flag;
  };

  const [formValues, setFormValues] = useState(initialValues);
  const handleDeltaFormOpen = (bin) => {
    setDeltaFormOpen(!deltaFormOpen);
    setCurrentBin(bin);
  };

  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container>
      <DeltaTable
        toggleFormOpen={handleDeltaFormOpen}
        setFormValues={setFormValues}
        initialValues={initialValues}
        confirmDeleteOpen={handleConfirmOpen}
        setConfirmDeleteID={setConfirmDeleteID}
      />
      <DeltaForm
        toggleOpen={handleDeltaFormOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        open={deltaFormOpen}
        setSnackbarProps={setSnackbarProps}
        isValidRange={isValidRange}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        id={confirmDeleteID}
        handleClose={handleConfirmClose}
        setSnackbarProps={setSnackbarProps}
      />
    </Container>
  );
}
