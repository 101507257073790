import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleActiveAsync } from "../../../Redux/usersSlice";

export default function ConfirmDialog(props) {
  const { open, handleClose, data, setSnackbarProps } = props;
  const dispatch = useDispatch();
  const handleConfirm = async () => {
    try {
      await dispatch(toggleActiveAsync(data))
        .unwrap()
        .then(() => {
          setSnackbarProps({
            open: true,
            msg: "Changed active state of the user",
            severity: "success",
          });
          handleClose();
        });
    } catch (e) {
      setSnackbarProps({
        open: true,
        msg: "Failed to change state of the user!",
        severity: "error",
      });
      handleClose();
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you confirm changing the user active state?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
