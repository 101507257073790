import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { approveUsersAsync } from "../../../Redux/usersSlice";
import { Button, Switch, TextField } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const headCells = [
  {
    id: "user_name",
    numeric: true,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "isactive",
    numeric: false,
    disablePadding: false,
    label: "Active Status",
  },
  {
    id: "approval",
    numeric: true,
    disablePadding: false,
    label: "Aproval",
  },
  {
    id: "last_checked",
    numeric: true,
    disablePadding: false,
    label: "Last Login",
  },
  {
    id: "change",
    numeric: true,
    disablePadding: false,
    label: "Change",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ addForm, search, setSearch, setPage }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Users
      </Typography>
      <TextField
        id="search-users"
        label="Search"
        variant="standard"
        placeholder="Enter Name"
        value={search}
        onChange={(e) => {
          setPage(0);
          setSearch(e.target.value);
        }}
      />
      <Tooltip title="Add User">
        <IconButton onClick={addForm}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function UsersTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const {
    addUserFormOpen,
    changePasswordFormOpen,
    setEditFormData,
    confirmToggleOpen,
    setConfirmToggleData,
  } = props;
  const users = useSelector((state) => state.users.users);
  let rows = users;
  const [search, setSearch] = useState("");

  if (search === "") {
    rows = users;
  } else {
    rows = users.filter((user) => {
      return user.username.toLowerCase().includes(search.toLowerCase());
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActiveChange = (id, isActive) => {
    setConfirmToggleData({ isActive: isActive, id: id });
    confirmToggleOpen();
  };

  const handleApprove = (id) => {
    dispatch(approveUsersAsync({ id, isApproved: true }));
  };
  const handleReject = (id) => {
    dispatch(approveUsersAsync({ id, isApproved: false }));
  };

  const handlechangePasswordFormOpen = (id) => {
    setEditFormData({ id, password: "" });
    changePasswordFormOpen();
  };

  // const handleEditUserFormOpen = (id) => {
  //   editUserFormOpen(id);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "90%", height: "90%", mb: 2 }} elevation={5}>
        <EnhancedTableToolbar
          addForm={addUserFormOpen}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.username}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: 70,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                color: row.isActive ? "green" : "red",
                                fontSize: 10,
                              }}
                            />
                            <Typography variant="body2" sx={{ mx: 1 }}>
                              {row.isActive ? "Active" : "Inactive"}
                            </Typography>
                          </Box>
                          <Box>
                            <Switch
                              checked={row.isActive}
                              onChange={(event) => {
                                handleActiveChange(
                                  row.id,
                                  event.target.checked
                                );
                              }}
                              disabled={!row.isApproved}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {row.isApproved ? (
                          "Approved"
                        ) : row.isApproved === null ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => handleApprove(row.id)}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ ml: 1 }}
                              color="secondary"
                              onClick={() => handleReject(row.id)}
                            >
                              Reject
                            </Button>
                          </Box>
                        ) : (
                          "Rejected"
                        )}
                      </TableCell>
                      <TableCell align="center">{row.updatedAt}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          disabled={!row.isApproved}
                          size="small"
                          onClick={() => handlechangePasswordFormOpen(row.id)}
                        >
                          Change Password
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
