import ResetModal from '../../Components/ResetModal';

import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  Link as Anchor,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import CenteredForm from '../../Components/CenteredForm';

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Link, useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUserAsync, logOutOnSessionExpire } from '../../Redux/authSlice';
import React from 'react';
import { getRole } from '../../Helpers/deps';

export default function Login() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(true);
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(true);
  const initialValues = { email: '', password: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [requestError, setRequestError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(() => ({ ...formValues, [name]: value }));
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleSubmit = async (e) => {
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      await dispatch(
        loginUserAsync({
          email: formValues.email,
          password: formValues.password,
          remember: remember,
        })
      )
        .unwrap()
        .then(() => {
          setTimeout(() => {
            dispatch(logOutOnSessionExpire());
          }, 86400000);

          const isAdmin = getRole().includes('ROLE_ADMIN');
          navigate(isAdmin ? '/admin/dashboard' : '/user/map/', {
            replace: true,
          });
        })
        .catch((error) => {
          setRequestError(error);
        });
    }
  };

  const validate = (values) => {
    const errors = {};
    //eslint-disable-next-line
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //eslint-disable-next-line
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!values.email) {
      errors.email = 'E-mail is required!';
    } else if (!email_regex.test(values.email)) {
      errors.email = 'This is not a valid email';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    } else if (!password_regex.test(values.password)) {
      errors.password =
        'Password must be min 8 characters at least 1 uppercase letter, 1 number and 1 special character';
    }
    return errors;
  };

  const togglePassword = () => setShowPassword(!showPassword);
  const toggleRemember = (e) => setRemember(e.target.checked);

  return (
    <CenteredForm>
      <Typography
        variant='h5'
        sx={{
          textAlign: 'center',
        }}
      >
        Login to your account
      </Typography>

      <Typography
        variant='subtitle1'
        sx={{
          textAlign: 'center',
          margin: 2,
        }}
      >
        Don't have an account?{' '}
        <Link to='/register'>
          <span style={{ color: '#2a81e2' }}> Register</span>
        </Link>
      </Typography>

      <TextField
        name='email'
        value={formValues.email}
        onChange={handleChange}
        label='E-mail'
        variant='outlined'
        placeholder='Please enter your email'
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                padding: 1,
              }}
              position='end'
            >
              <EmailIcon
                sx={{
                  color: 'rgba(0,0,0,0.8)',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          height: '2rem',
        }}
      >
        <Typography variant='caption' component='h2' color='error.main'>
          {formErrors.email}
        </Typography>
      </Box>

      <TextField
        name='password'
        value={formValues.password}
        onChange={handleChange}
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        placeholder='Please enter password'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {showPassword ? (
                  <VisibilityOffIcon
                    sx={{
                      color: 'error.main',
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{
                      color: 'success.main',
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          height: '2rem',
        }}
      >
        <Typography variant='caption' component='h2' color='error.main'>
          {formErrors.password}
        </Typography>
      </Box>

      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={remember} onChange={toggleRemember} />}
          label='Keep me logged in'
        />
      </FormGroup>

      <Button
        variant='contained'
        onClick={handleSubmit}
        color='primary'
        sx={{
          padding: 1,
        }}
      >
        Log In
      </Button>
      <Box
        sx={{
          height: '1rem',
        }}
      >
        <Typography
          variant='subtitle1'
          component='h2'
          color='error.main'
          align='center'
        >
          {requestError}
        </Typography>
      </Box>
      <Anchor
        sx={{
          textAlign: 'center',
          textDecoration: 'none',
          cursor: 'pointer',
          margin: 1,
        }}
        onClick={handleOpen}
      >
        Forgot Password?
      </Anchor>

      <ResetModal modalOpen={open} toggleOpen={handleOpen} />
    </CenteredForm>
  );
}
