import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { changePasswordAsync } from "../../../Redux/usersSlice";

export default function ChangePasswordModal(props) {
  const { handleClose, open, editFormData, setEditFormData, setSnackbarProps } =
    props;

  const dispatch = useDispatch();

  const handleChangePassword = async () => {
    await dispatch(changePasswordAsync({ ...editFormData }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          msg: "Password Changed for the user",
          severity: "success",
        });
        handleClose();
      })
      .catch((error) => {
        setSnackbarProps({
          open: true,
          msg: "Failed to Change Password, Try Again!",
          severity: "error",
        });
      });
  };

  const handleEditFormData = (evt) => {
    const { name, value } = evt.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="rank"
            name="id"
            label="ID"
            type="text"
            onChange={handleEditFormData}
            value={editFormData.id}
            sx={{ display: "none" }}
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            name="password"
            label="New Password"
            placeholder="Enter New Password"
            type="password"
            onChange={handleEditFormData}
            value={editFormData.password}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleChangePassword}>Change Password</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
