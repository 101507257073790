import React from 'react';
import { Box } from '@mui/system';

export default function CenteredForm({children}) {
    return (
       
        <Box 
        component="div"
        sx={{
        height:"100vh",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor:{sm:"primary.main"}
        }}       
        >
            <Box
            component="div"
            sx={{
               width:{ xs: '100%',sm:"25rem", md:"25rem", xl: '27rem' },
               height:{xs:"70vh",sm:"30rem"},
               backgroundColor:"secondary.main",
               borderRadius:2,
               display:"flex",
               flexDirection:"column",
               justifyContent:"space-between",
               padding:3
            }}
            >
            {children}
            </Box>

        </Box>
            
   
    )
}
