// set local storage
export const setDataInLocal = (key, data) => {
  if (key === 'user_token') {
    const now = new Date();
    const ttl = 86400000; //10 seconds in milliseconds
    const sessionData = { token: data, expiry: now.getTime() + ttl };
    localStorage.setItem(key, JSON.stringify(sessionData));
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getDataInLocal = (key) => {
  let sessionData = '';
  if (key === 'user_token') {
    const sessionInfoFromStorage = localStorage.getItem(key);
    if (sessionInfoFromStorage) {
      const sessionInfo = JSON.parse(sessionInfoFromStorage);
      const now = new Date();
      if (now.getTime() > sessionInfo.expiry) {
        localStorage.removeItem(key);
        return null;
      } else {
        return sessionInfo;
      }
    }
	else {
		return null;
	}
  } else {
    sessionData = localStorage.getItem(key) || '{}';
    sessionData = JSON.parse(sessionData);
  }
  return sessionData;
};

export const removeDataInLocal = (key) => {
  localStorage.removeItem(key);
};

export const getAuthToken = () => {
  return getDataInLocal('user_token');
};

export const getRole = () => {
  return getDataInLocal('user_role');
};

export const removeAuthToken = () => {
  removeDataInLocal('user_token');
};

export const setAuthToken = (props) => {
  setDataInLocal('user_token', props ? `${props}` : '');
};

export const setRole = (props) => {
  setDataInLocal('user_role', props ? `${props}` : '');
};

export const objectCompare = (newObj, oldObj) => {
  return JSON.stringify(newObj) === JSON.stringify(oldObj);
};

export const isNonEmptyValue = (value) => {
  return value !== undefined && value !== null && value !== '';
};

export const isArray = (props) => {
  const givenType = typeof props;
  const isArrayBooleanVal = props instanceof Array;
  return givenType && isArrayBooleanVal;
};

export const isNonEmptyArray = (props) => {
  const isArr = isNonEmptyValue(props) && isArray(props);
  return props && props.length && isArr;
};

export const isNonEmptyObject = (obj) => {
  return !(
    obj === undefined ||
    obj === null ||
    !(obj instanceof Object) ||
    Object.keys(obj).length === 0
  );
};

export const getQueryParams = () => {
  const currentURL = window.location.href;
  const rawQueryString = currentURL.split('?');
  const queryParams =
    rawQueryString && rawQueryString.length > 1
      ? rawQueryString[1].split('&')
      : '';
  const queryParamObj = {};
  for (let i = 0; i < queryParams.length; i += 1) {
    const queryParam = queryParams[i];
    if (queryParam.includes('=')) {
      const paramKey = queryParam.split('=')[0];
      const paramValue = queryParam.split('=')[1];
      queryParamObj[paramKey] = paramValue;
    }
  }
  return queryParamObj;
};

export const dateFormatter = (string) => {
  const date = new Date(string);
  const dateStr = `${`00${date.getMonth() + 1}`.slice(
    -2
  )}/${`00${date.getDate()}`.slice(
    -2
  )}/${date.getFullYear()} ${`00${date.getHours()}`.slice(
    -2
  )}:${`00${date.getMinutes()}`.slice(-2)}:${`00${date.getSeconds()}`.slice(
    -2
  )}`;
  return dateStr;
};

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const getDomain = () => {
  const url = window.location.host;
  const parts = url.split('.');
  return parts.length > 1 && parts?.[0] !== 'frontend-dot-net-zero-pathways'
    ? parts?.[0]
    : 'nsw';
};
