import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import { Button, Tooltip, CircularProgress } from '@mui/material';
import axios from 'axios';

const headCells = [
  {
    id: 'sl_no',
    numeric: true,
    disablePadding: true,
    label: 'Sl No.',
  },
  {
    id: 'csv_file',
    numeric: false,
    disablePadding: false,
    label: 'File Name',
  },

  {
    id: 'download',
    numeric: true,
    disablePadding: false,
    label: 'Download',
  },
];

const BASE_URL = process.env.REACT_APP_BASE_URL;

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { toggleFormOpen, setSnackbarProps, token } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 4 100%' }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Transfer Data to S3
      </Typography>
      <Button
        sx={{ width: '200px', mr: 1 }}
        onClick={toggleFormOpen}
        variant='outlined'
      >
        Dump Data
      </Button>
      <Button
        sx={{ width: '200px', px: 0 }}
        onClick={async () => {
          const current = new Date();
          try {
            await axios.get(
              `${BASE_URL}/api/backup?date=${current.toISOString()}`,
              { headers: { 'x-access-token': token } }
            );
            setSnackbarProps({
              open: true,
              severity: 'success',
              msg: 'Data dump successful!',
            });
          } catch (e) {
            setSnackbarProps({
              open: true,
              severity: 'error',
              msg: 'Failed to dump data!',
            });
          }
        }}
        variant='outlined'
      >
        Dump Recent Data
      </Button>
    </Toolbar>
  );
};

export default function CSVTable(props) {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { toggleFormOpen, setSnackbarProps, token } = props;
  const [isDowloading, setIsDownloading] = useState(false);
  const [fileKey, setFileKey] = useState();

  const [files, setFiles] = useState([]);

  let rows = files;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const getCSVFilesResponse = await axios.get(
      `${BASE_URL}/api/listBackupCsv`,
      {
        headers: {
          'x-access-token': token,
        },
      }
    );
    setFiles(getCSVFilesResponse.data.list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: '100%',
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ width: '90%', height: '90%', my: 5 }} elevation={5}>
        <EnhancedTableToolbar
          setPage={setPage}
          toggleFormOpen={toggleFormOpen}
          token={token}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'medium'}
          >
            <EnhancedTableHead
              rowCount={rows.length}
              setSnackbarProps={setSnackbarProps}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={page * 5 + index + 1}>
                      <TableCell
                        component='th'
                        scope='row'
                        padding='none'
                        align='center'
                      >
                        {page * 5 + index + 1}
                      </TableCell>
                      <TableCell align='left'>{row.Key}</TableCell>
                      <TableCell align='left'>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Tooltip title='Download'>
                            <IconButton
                              onClick={async () => {
                                setIsDownloading(true);
                                setFileKey(row.Key);
                                try {
                                  const downloadResponse = await axios.get(
                                    `${BASE_URL}/api/getCsvDownloadLink?key=${row.Key}`,
                                    {
                                      headers: {
                                        'x-access-token': token,
                                      },
                                    }
                                  );
                                  const URL = downloadResponse.data.signedUrl;
                                  window.open(URL, '_blank');
                                  setTimeout(() => {
                                    setIsDownloading(false);
                                  }, 3000);
                                } catch (e) {
                                  setSnackbarProps({
                                    open: true,
                                    severity: 'error',
                                    msg: 'Failed to download. Try again!',
                                  });
                                }
                              }}
                              sx={{ color: 'black' }}
                            >
                              {isDowloading && row.Key === fileKey ? (
                                <CircularProgress
                                  size={25}
                                  sx={{ color: 'black' }}
                                />
                              ) : (
                                <DownloadIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
