import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  addDeltaColorAsync,
  updateDeltaColorAsync,
} from "../../../Redux/carColorSlice";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, TextField, Button } from "@mui/material";

export default function CarColorForm(props) {
  const {
    toggleOpen,
    isValidRange,
    open,
    formValues,
    setFormValues,
    setSnackbarProps,
  } = props;

  const colorPicker = useRef();

  const dispatch = useDispatch();

  const isEditing = formValues.id;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const checkPrecision = (value) => {
    if (value && value.includes(".")) {
      const afterDecimalCount = value.split(".")[1].length;
      if (afterDecimalCount > 1) {
        return false;
      }
    }
    return true;
  };

  const handleAddColor = async () => {
    if (isValidRange(formValues, false)) {
      if (
        checkPrecision(formValues.range_from) &&
        checkPrecision(formValues.range_to)
      ) {
        await dispatch(addDeltaColorAsync({ ...formValues }))
          .unwrap()
          .then(() => {
            setSnackbarProps({
              open: true,
              severity: "success",
              msg: "Added delta color successfully",
            });
            toggleOpen();
          })
          .catch(() => {
            setSnackbarProps({
              open: true,
              severity: "error",
              msg: "The range already exists",
            });
          });
      } else {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Please enter 1 decimal precision for range values",
        });
      }
    } else {
      setSnackbarProps({
        open: true,
        severity: "error",
        msg: "The range already exists",
      });
    }
  };

  const handleUpdateColor = async () => {
    if (isValidRange(formValues, true)) {
      if (
        checkPrecision(formValues.range_from) &&
        checkPrecision(formValues.range_to)
      ) {
        await dispatch(updateDeltaColorAsync({ ...formValues }))
          .unwrap()
          .then(() => {
            setSnackbarProps({
              open: true,
              severity: "success",
              msg: "Updated successfully",
            });
            toggleOpen();
          })
          .catch((err) => {
            setSnackbarProps({
              open: true,
              severity: "error",
              msg: "Failed to update, Try again!",
            });
          });
      } else {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Please enter 1 decimal precision for range values",
        });
      }
    } else {
      setSnackbarProps({
        open: true,
        severity: "error",
        msg: "Range exists",
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={toggleOpen} sx={{ height: "100%" }}>
        <DialogTitle>{isEditing ? "Update" : "Add"} Color</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-number"
              label="Delta From"
              name="range_from"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
              size="small"
              value={formValues.range_from}
            />
            <TextField
              id="outlined-number"
              label="Delta To"
              name="range_to"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: 2 }}
              onChange={handleInputChange}
              size="small"
              value={formValues.range_to}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                }}
              >
                Pick Color
              </Typography>
              <Box
                component="input"
                type="color"
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
                ref={colorPicker}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Cancel</Button>
          <Button onClick={isEditing ? handleUpdateColor : handleAddColor}>
            {isEditing ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
