import React, { useState } from "react";
import AddRegionModal from "./AddRegionModal";
import EditRegionModal from "./EditRegionModal";
import RegionsTable from "./RegionsTable";
import ConfirmDialog from "./ConfirmDialog";
import Snackbar from "../../../Components/Snackbar/Snackbar";

export default function Regions({ token }) {
  const [addRegionFormOpen, setAddRegionFormOpen] = useState(false);
  const [editRegionFormOpen, setEditRegionFormOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [editFormData, setEditFormData] = useState({
    id: "",
    dma_rank: "",
    dma_name: "",
    dma_code: "",
    latitude: "",
    longitude: "",
  });

  const [addFormData, setAddFormData] = useState({
    dma_rank: "",
    dma_name: "",
    dma_code: "",
    latitude: "",
    longitude: "",
  });

  const [confirmDeleteID, setConfirmDeleteID] = useState(null);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarProps({ ...snackbarProps, open: false });
  };

  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleAddClickOpen = () => {
    setAddRegionFormOpen(true);
  };

  const handleAddClose = () => {
    setAddRegionFormOpen(false);
  };

  const handleEditClickOpen = (row) => {
    setEditRegionFormOpen(true);
    setEditFormData({
      id: row.id,
      dma_rank: row.dma_rank,
      dma_name: row.dma_name,
      dma_code: row.dma_code,
      latitude: row.dma_centroid
        ? row.dma_centroid.replace("POINT ", "").split(" ")[0].slice(1)
        : "",
      longitude: row.dma_centroid
        ? row.dma_centroid.replace("POINT ", "").split(" ")[1].slice(0, -1)
        : "",
    });
  };

  const handleEditClose = () => {
    setEditRegionFormOpen(false);
  };

  return (
    <>
      <AddRegionModal
        handleClickOpen={handleAddClickOpen}
        handleClose={handleAddClose}
        open={addRegionFormOpen}
        addFormData={addFormData}
        setAddFormData={setAddFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <EditRegionModal
        handleClickOpen={handleEditClickOpen}
        handleClose={handleEditClose}
        open={editRegionFormOpen}
        editFormData={editFormData}
        setEditFormData={setEditFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <RegionsTable
        addRegionFormOpen={handleAddClickOpen}
        editRegionFormOpen={handleEditClickOpen}
        confirmDeleteOpen={handleConfirmOpen}
        setConfirmDeleteID={setConfirmDeleteID}
        token={token}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        id={confirmDeleteID}
        handleClose={handleConfirmClose}
        setSnackbarProps={setSnackbarProps}
      />
      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}
