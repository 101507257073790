import React, { useEffect, useState } from 'react';
import Header from './Header';
import Drawer from './Drawer';
import { userDrawerContent, adminDrawerContent } from './DrawerContent';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { Outlet, useNavigate } from 'react-router';
import { getDataInLocal } from '../../Helpers/deps';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDevicesAsync, getAllRegionsAsync } from '../../Redux/dataSlice';
import { getUsersAsync } from '../../Redux/usersSlice';
import { getDeltaColorsAsync } from '../../Redux/carColorSlice';
import {
  getDeltaBucketsAsync,
  getSignalBucketsAsync,
} from '../../Redux/chartSlice';
import SessionExpiredDialog from '../../Components/SessionExpiredDialog/SessionExpiredDialog';

const user = getDataInLocal();

export function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const isSessionExpired = useSelector((state) => state.auth.isSessionExpired);
  const [loading, setLoading] = useState(true);

  const drawerContent = isAdmin ? adminDrawerContent : userDrawerContent;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await dispatch(getAllRegionsAsync());
    await dispatch(getAllDevicesAsync());
    await dispatch(getDeltaBucketsAsync());
    await dispatch(getSignalBucketsAsync());
    await dispatch(getDeltaColorsAsync());
    isAdmin && (await dispatch(getUsersAsync()));
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  return !isSessionExpired ? (
    loading ? (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <Header
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          title='ATSC Bitpath'
          user={user}
        />
        <Drawer open={drawerOpen} toggleDrawer={toggleDrawer}>
          <List>
            {drawerContent.map((obj) => {
              return (
                <ListItem
                  key={obj.path}
                  color='primary'
                  button
                  onClick={() => {
                    navigate(obj.path);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>{obj.icon}</ListItemIcon>
                  <ListItemText>{obj.text}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Drawer>

        <Box
          sx={{
            height: '100vh',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    )
  ) : (
    <SessionExpiredDialog />
  );
}
