import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDeltaColorsAsync = createAsyncThunk(
  "carColor/getDeltaColorsAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      const deltaColorsResponse = await axios.get(
        `${BASE_URL}/api/getAllDeltaRange`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return deltaColorsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const addDeltaColorAsync = createAsyncThunk(
  "carColor/addDeltaColorAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const addDeltaColorResponse = await axios.post(
        `${BASE_URL}/api/deltaRange`,
        {
          ...payload,
          relation: "to",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return addDeltaColorResponse.data;
    } catch (err) {
      return rejectWithValue("Couldn't Add Region. Try Again!", err);
    }
  }
);

export const updateDeltaColorAsync = createAsyncThunk(
  "carColor/updateDeltaColorAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.put(
        `${BASE_URL}/api/deltaRange/${payload.id}`,
        {
          ...payload,
          relation: "to",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Add Region. Try Again!", err);
    }
  }
);

export const deleteDeltaColorAsync = createAsyncThunk(
  "carColor/deleteDeltaColorAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.delete(`${BASE_URL}/api/deltaRange/${payload.id}`, {
        headers: {
          "x-access-token": token,
        },
      });
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Delete Delta Color. Try Again!", err);
    }
  }
);

export const carColorSlice = createSlice({
  name: "carColor",
  initialState: {
    deltaColors: [],
  },
  reducers: {},
  extraReducers: {
    [getDeltaColorsAsync.fulfilled]: (state, action) => {
      return { ...state, deltaColors: action.payload };
    },
    [addDeltaColorAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        deltaColors: [...state.deltaColors, { ...action.payload }],
      };
    },
    [updateDeltaColorAsync.fulfilled]: (state, action) => {
      const colors = [...state.deltaColors];
      const colorIndex = colors.findIndex(
        (color) => color.id === action.payload.id
      );

      if (colorIndex > -1) {
        colors[colorIndex] = action.payload;
      }
      return { deltaColors: [...colors] };
    },
    [deleteDeltaColorAsync.fulfilled]: (state, action) => {
      const filteredDeltaColors = state.deltaColors.filter(
        (color) => color.id !== action.payload.id
      );
      return { ...state, deltaColors: filteredDeltaColors };
    },
  },
});

export default carColorSlice.reducer;
