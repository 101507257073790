import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { addRegionAsync } from "../../../Redux/regionsSlice";

export default function AddRegionModal(props) {
  const { handleClose, open, addFormData, setAddFormData, setSnackbarProps } =
    props;
  const handleAddFormData = (evt) => {
    const { name, value } = evt.target;
    setAddFormData({ ...addFormData, [name]: value });
  };

  const dispatch = useDispatch();

  const handleAddRegion = async () => {
    await dispatch(addRegionAsync({ ...addFormData }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          severity: "success",
          msg: "Added region successfully",
        });
        handleClose();
      })
      .catch((error) => {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Failed to add region, Try again!",
        });
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Region</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="rank"
            name="dma_rank"
            label="DMA Rank"
            type="number"
            onChange={handleAddFormData}
            value={addFormData.dma_rank}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="code"
            name="dma_code"
            label="DMA Code"
            type="number"
            value={addFormData.dma_code}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            name="dma_name"
            label="DMA Name"
            type="text"
            value={addFormData.dma_name}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="latitude"
            name="latitude"
            label="Centroid Latitude"
            type="text"
            placeholder="latitude"
            value={addFormData.latitude}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="longitude"
            name="longitude"
            label="Centroid Longitude"
            type="text"
            placeholder="longitude"
            value={addFormData.longitude}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddRegion}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
