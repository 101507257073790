import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard } from "./Pages/Dashboard";
import Live from "./Components/Map/Live";
import NotFound from "./Pages/NotFound/NotFound";
import RequireAuth from "./Auth/RequireAuth";
import RequireAdminAuth from "./Auth/RequireAdminAuth";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import AdminDashboard from "./Pages/Dashboard/AdminDashboard";
import History from "./Components/Map/History";
import { getRole } from "./Helpers/deps";
import Regions from "./Pages/Dashboard/Regions/Regions";
import Devices from "./Pages/Dashboard/Devices/Devices";
import Users from "./Pages/Dashboard/Users/Users";
import CreateBackUp from "./Pages/Dashboard/CreateBackUp/CreateBackUp";
import Car from "./Pages/Dashboard/Car/Car";
import ManageChart from "./Pages/Dashboard/Chart/ManageChart";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route
        path="user"
        element={
          <RequireAuth>
            {" "}
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="map" element={<Live />} />
        <Route path="history" element={<History />} />
      </Route>
      <Route
        path="admin"
        element={
          <RequireAdminAuth>
            {" "}
            <Dashboard />
          </RequireAdminAuth>
        }
      >
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="regions" element={<Regions />} />
        <Route path="users" element={<Users />} />
        <Route path="devices" element={<Devices />} />
        <Route path="backup" element={<CreateBackUp />} />
        <Route path="car" element={<Car />} />
        <Route path="chart" element={<ManageChart />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function Home() {
  const role = getRole();

  const isAdmin =
    typeof role === "string" ? role.includes("ROLE_ADMIN") : false;

  return isAdmin ? (
    <Navigate to="admin/dashboard" />
  ) : (
    <Navigate to="user/map" />
  );
}

export default Router;
