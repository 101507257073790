import React, { useState } from "react";
import { Container } from "@mui/material";
import CSVTable from "./CSVTable";
import BackUpForm from "./BackUpForm";
import Snackbar from "../../../Components/Snackbar/Snackbar";
import { useSelector } from "react-redux";

export default function CreateBackUp() {
  const [backUpFormOpen, setBackUpFormOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarProps({ ...snackbarProps, open: false });
  };

  const handleBackUpFormOpen = () => {
    setBackUpFormOpen(!backUpFormOpen);
  };

  return (
    <Container>
      <CSVTable
        toggleFormOpen={handleBackUpFormOpen}
        setSnackbarProps={setSnackbarProps}
        token={token}
      />
      <BackUpForm
        toggleOpen={handleBackUpFormOpen}
        open={backUpFormOpen}
        setSnackbarProps={setSnackbarProps}
        token={token}
      />
      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </Container>
  );
}
