import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDeltaBucketsAsync = createAsyncThunk(
  "chartBuckets/getDeltaBucketsAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const deltaBucketsResponse = await axios.get(
        `${BASE_URL}/api/getAllBin?delta=1`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return deltaBucketsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getSignalBucketsAsync = createAsyncThunk(
  "chartBuckets/getSignalBucketsAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const signalBucketsResponse = await axios.get(
        `${BASE_URL}/api/getAllBin?signal=1`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return signalBucketsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const addDeltaBucketAsync = createAsyncThunk(
  "chartBuckets/addDeltaBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const addDeltaBucketResponse = await axios.post(
        `${BASE_URL}/api/bin`,
        {
          ...payload,
          name: "delta",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return addDeltaBucketResponse.data;
    } catch (err) {
      return rejectWithValue("Couldn't Add Delta Bucket. Try Again!", err);
    }
  }
);

export const addSignalBucketAsync = createAsyncThunk(
  "chartBuckets/addSignalBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const addSignalBucketResponse = await axios.post(
        `${BASE_URL}/api/bin`,
        {
          ...payload,
          name: "signal",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return addSignalBucketResponse.data;
    } catch (err) {
      return rejectWithValue("Couldn't Add Signal Bucket. Try Again!", err);
    }
  }
);

export const updateDeltaBucketAsync = createAsyncThunk(
  "chartBuckets/updateDeltaBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.put(
        `${BASE_URL}/api/bin/${payload.id}`,
        {
          ...payload,
          name: "delta",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't update delta bucket. Try Again!", err);
    }
  }
);

export const updateSignalBucketAsync = createAsyncThunk(
  "chartBuckets/updateSignalBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.put(
        `${BASE_URL}/api/bin/${payload.id}`,
        {
          ...payload,
          name: "signal",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't update signal bucket. Try Again!", err);
    }
  }
);

export const deleteDeltaBucketAsync = createAsyncThunk(
  "chartBuckets/deleteDeltaBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.delete(`${BASE_URL}/api/bin/${payload.id}`, {
        headers: {
          "x-access-token": token,
        },
      });
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Delete Delta Bucket. Try Again!", err);
    }
  }
);

export const deleteSignalBucketAsync = createAsyncThunk(
  "chartBuckets/deleteSignalBucketAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.delete(`${BASE_URL}/api/bin/${payload.id}`, {
        headers: {
          "x-access-token": token,
        },
      });
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Delete Signal Bucket. Try Again!", err);
    }
  }
);

export const chartSlice = createSlice({
  name: "chartBuckets",
  initialState: {
    deltaBuckets: [],
    signalBuckets: [],
  },
  reducers: {},
  extraReducers: {
    [getDeltaBucketsAsync.fulfilled]: (state, action) => {
      return { ...state, deltaBuckets: action.payload };
    },
    [getSignalBucketsAsync.fulfilled]: (state, action) => {
      return { ...state, signalBuckets: action.payload };
    },
    [addDeltaBucketAsync.fulfilled]: (state, action) => {
      if (action.payload.upperLimit) {
        return {
          ...state,
          deltaBuckets: [...state.deltaBuckets, { ...action.payload }],
        };
      } else {
        return {
          ...state,
          deltaBuckets: [
            ...state.deltaBuckets,
            { ...action.payload, upperLimit: "+" },
          ],
        };
      }
    },
    [addSignalBucketAsync.fulfilled]: (state, action) => {
      if (action.payload.upperLimit) {
        return {
          ...state,
          signalBuckets: [...state.signalBuckets, { ...action.payload }],
        };
      } else {
        return {
          ...state,
          signalBuckets: [
            ...state.signalBuckets,
            { ...action.payload, upperLimit: "+" },
          ],
        };
      }
    },
    [updateDeltaBucketAsync.fulfilled]: (state, action) => {
      const deltaBuckets = [...state.deltaBuckets];
      const deltaBucketIndex = deltaBuckets.findIndex(
        (bucket) => bucket.id === action.payload.id
      );

      if (deltaBucketIndex > -1) {
        deltaBuckets[deltaBucketIndex] = action.payload;
      }

      return { ...state, deltaBuckets };
    },
    [updateSignalBucketAsync.fulfilled]: (state, action) => {
      const signalBuckets = [...state.signalBuckets];
      const signalBucketIndex = signalBuckets.findIndex(
        (bucket) => bucket.id === action.payload.id
      );

      if (signalBucketIndex > -1) {
        signalBuckets[signalBucketIndex] = action.payload;
      }
      return { ...state, signalBuckets };
    },
    [deleteDeltaBucketAsync.fulfilled]: (state, action) => {
      const filteredDeltaBuckets = state.deltaBuckets.filter(
        (color) => color.id !== action.payload.id
      );
      return { ...state, deltaBuckets: filteredDeltaBuckets };
    },
    [deleteSignalBucketAsync.fulfilled]: (state, action) => {
      const filteredSignalBuckets = state.signalBuckets.filter(
        (color) => color.id !== action.payload.id
      );
      return { ...state, signalBuckets: filteredSignalBuckets };
    },
  },
});

export default chartSlice.reducer;
