import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export default function HistoryInfoBox() {
  const deviceInfo = useSelector((state) => state.data.historyDeviceInfo);

  const selectedRegion = useSelector(
    (state) => state.data.selectedHistoryRegion
  );
  const selectedDevices = useSelector(
    (state) => state.data.selectedHistoryDevices
  );

  const regionDevices = useSelector((state) => state.data.devices);

  const regionDeviceIDs = regionDevices.map((device) => device.name);

  const infoToShow = useRef();
  infoToShow.current = [];

  const getValueToDisplay = (val) => {
    let value = val.toString();
    if (val.toString().includes('m')) {
      value = value.replace('m', ' ').trim();
    }
    if (value === '0') {
      return value;
    } else {
      const indexOfDot = value.indexOf('.');
      let countAfterDot = 0;
      for (let i = indexOfDot + 1; i < value.length; i++) {
        countAfterDot++;
      }
      if (countAfterDot > 6) {
        return value.slice(0, 8);
      } else {
        let noOfZerosToBeAppended = 6 - countAfterDot;
        return value + '0'.repeat(noOfZerosToBeAppended);
      }
    }
  };

  if (selectedRegion.length === 0 && selectedDevices.includes('All')) {
    infoToShow.current = deviceInfo;
  } else if (selectedRegion.length > 0 && selectedDevices.includes('All')) {
    infoToShow.current = deviceInfo.filter((device) =>
      regionDeviceIDs.includes(device.id)
    );
  } else {
    infoToShow.current = deviceInfo.filter((device) =>
      selectedDevices.includes(device.id)
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '25rem',
        maxHeight: '40rem',
        textAlign: 'center',
        right: 3,
        bottom: 50,
        backgroundColor: 'rgba(255,255,255,0.9)',
      }}
    >
      {regionDeviceIDs.length !== 0 ? (
        <table style={{ margin: '0 auto', textAlign: 'center' }}>
          <tbody>
            <tr>
              <th style={{ padding: '5px' }}>Device ID</th>
              <th style={{ padding: '5px' }}>Med. Signal Strength</th>
              <th style={{ padding: '5px' }}>Med. Delta</th>
            </tr>
            {infoToShow.current.length !== 0 ? (
              infoToShow.current.map((device) => (
                <tr key={device.id}>
                  <td style={{ padding: '5px' }}>{device.id}</td>
                  <td style={{ padding: '5px' }}>
                    {device.signal_strength || 0}
                  </td>
                  <td style={{ padding: '5px' }}>
                    {getValueToDisplay(device.euclideanDistance) + ' m'}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='3' style={{ textAlign: 'center' }}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <h2>No Devices</h2>
      )}
    </Box>
  );
}
