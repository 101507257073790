import mapboxgl from "mapbox-gl";

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const convertJSONToString = (jsonParsedArray) => {
  let str = "";
  for (let key in jsonParsedArray) {
    if (jsonParsedArray.hasOwnProperty(key)) {
      str += "<p>" + key + " = " + jsonParsedArray[key] + "</p>";
    }
  }
  return str;
};

// Create a popup, but don't add it to the map yet.
const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
});

export function showPopUp(map, id) {
  map.on("mouseenter", id, (e) => {
    map.getCanvas().style.cursor = "pointer";

    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.description;

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on("mouseleave", id, () => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
}

// Draw Line for given coordinates

export const drawLine = (map, coordinates) => {
  map.addSource("route1", {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
    },
  });
  map.addLayer({
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#eb0202",
      "line-width": 8,
    },
  });
};

//   Getting direction bw source and destination
export async function getRoute(map, start, end, color) {
  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
    { method: "GET" }
  );
  const json = await query.json();
  const data = json.routes[0];
  const route = data.geometry.coordinates;
  const geojson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: route,
    },
  };
  // if the route already exists on the map, we'll reset it using setData
  if (map.getSource("route")) {
    map.getSource("route").setData(geojson);
  }
  // otherwise, we'll make a new request
  else {
    map.addLayer({
      id: "route",
      type: "line",
      source: {
        type: "geojson",
        data: geojson,
      },
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 5,
        "line-opacity": 0.75,
      },
    });
  }
}

export function getDirection(map, start, end) {
  getRoute(map, start, end);
  // Add starting point to the map
  map.addLayer({
    id: "point",
    type: "circle",
    source: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: start,
            },
          },
        ],
      },
    },
    paint: {
      "circle-radius": 10,
      "circle-color": "#3887be",
    },
  });
}

// getting Coordinates  of direction

const axios = require("axios");

export async function getCoordinates(src, dest, color, title) {
  const query = await axios.get(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${src[0]},${src[1]};${dest[0]},${dest[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
  );

  const json = query.data;
  const data = json.routes[0];
  const route = data.geometry.coordinates;

  return {
    type: "Feature",
    properties: {
      title: title,
      index: title,
      color: color,
    },
    geometry: {
      type: "LineString",
      coordinates: route,
    },
  };
}
