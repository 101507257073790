import React, { useState } from "react";
import { Box } from "@mui/material";
import Delta from "./Delta/Delta";
import Signal from "./Signal/Signal";
import Snackbar from "../../../Components/Snackbar/Snackbar";

export default function ManageChart() {
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarProps({ ...snackbarProps, open: false });
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `"delta signal"`,
        gridTemplateColumns: "1fr 1fr",
        height: "90%",
        alignItems: "center",
      }}
    >
      <Box sx={{ gridArea: "delta" }}>
        <Delta setSnackbarProps={setSnackbarProps} />
      </Box>
      <Box sx={{ gridArea: "signal" }}>
        <Signal setSnackbarProps={setSnackbarProps} />
      </Box>
      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </Box>
  );
}
