import { Container, Typography, Paper, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
  const regionCount = useSelector((state) => state.data.regions.length);
  const deviceCount = useSelector((state) => state.data.devices.length);
  const usersCount = useSelector((state) => state.users.users.length);
  const navigate = useNavigate();

  return (
    <Container maxWidth="100%" sx={{ height: "100%" }} disableGutters>
      <Typography
        variant="h5"
        sx={{
          textTransform: "uppercase",
          fontWeight: 500,
          textAlign: "center",
          backgroundColor: "#f0f3f7",
        }}
      >
        Admin Dashboard
      </Typography>

      <Grid
        spacing={4}
        container
        sx={{
          height: "90%",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "0 1rem",
          backgroundColor: "#f0f3f7",
        }}
      >
        <Grid xs={12} sm={3} item>
          <Paper
            sx={{
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/admin/regions");
            }}
          >
            <Typography variant="p" component="h3">
              Regions
            </Typography>
            <Box>
              <Typography variant="h3">{regionCount}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid xs={12} sm={3} item>
          <Paper
            sx={{
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/admin/devices");
            }}
          >
            <Typography variant="p" component="h3">
              Devices
            </Typography>
            <Box>
              <Typography variant="h3">{deviceCount}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid xs={12} sm={3} item>
          <Paper
            sx={{
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/admin/users");
            }}
          >
            <Typography variant="p" component="h3">
              Users
            </Typography>
            <Box>
              <Typography variant="h3">{usersCount}</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
