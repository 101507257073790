import React from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logOut } from '../../Redux/authSlice';

export default function SessionExpiredDialog() {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const isSessionExpired=useSelector(state=>state.auth.isSessionExpired);

  const handleClose = () => {
    dispatch(logOut());
    navigate('/',{replace:true})
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    borderRadius: 3,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems:'center'
  };

  return (
    <Modal open={isSessionExpired}  BackdropProps={{sx:{backgroundColor:'#2a81e2'}}} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant='h6' component='h2' align='center'>
          Session Expired! Please Login Again...
        </Typography>
        <Button size='small' variant='contained' sx={{width:'20%',mt:3}} onClick={handleClose}>Ok</Button>
      </Box>
    </Modal>
  );
}
