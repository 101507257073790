import { ResponsiveBar } from "@nivo/bar";
// import { BasicTooltip } from "@nivo/tooltip";
import { Box, Paper, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const MyResponsiveBar = ({ data, title }) => (
  <ResponsiveBar
    data={data}
    keys={["count"]}
    indexBy="range"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={({ id, data }) => String(data[`color`])}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={
      data.length === 0
        ? null
        : {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${title} Bin-range`,
            legendPosition: "middle",
            legendOffset: 32,
          }
    }
    tooltip={(data) => <BarTooltip data={data} />}
    axisLeft={
      data.length === 0
        ? null
        : {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "No. of Readings",
            legendPosition: "middle",
            legendOffset: -50,
          }
    }
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    role="application"
  />
);

const BarTooltip = (props) => {
  const { data } = props;

  return (
    <Paper
      sx={{
        color: "#000",
        background: "#fff",
        px: 1,
        py: 1,
      }}
      elevation={5}
    >
      <Typography> {`${data.id} : ${data.value}`}</Typography>
      <Typography> {`${data.data.percentage.toFixed(2)} %`}</Typography>
    </Paper>
  );
};

export default function BarChart({ data, isLoading, title, error }) {
  return (
    <Box
      sx={{
        position: "relative",
        height: "50%",
        width: "80%",
        background: "#fff",
        borderRadius: ".2em",
        boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ height: "100%", width: "110%", marginRight: "-5em" }}>
        <MyResponsiveBar data={data} title={title} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {data.length === 0 && (
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {isLoading ? "" : "No Data available"}
          </Typography>
        )}
        <LinearProgress
          sx={{ display: isLoading ? "block" : "none", width: "300px" }}
        />
      </Box>
    </Box>
  );
}
