import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Material UI
import {CssBaseline} from "@mui/material";
import { ThemeProvider } from '@mui/material';
import customTheme from './theme';
// React Router DOM
import {BrowserRouter } from 'react-router-dom';
// Redux 
import {store} from "./Redux/store"
import { Provider as ReduxProvider } from 'react-redux';


ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={customTheme}>
          <CssBaseline/>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
