import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  addDeltaBucketAsync,
  updateDeltaBucketAsync,
} from "../../../../Redux/chartSlice";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField, Button, Typography } from "@mui/material";

export default function DeltaForm(props) {
  const {
    toggleOpen,
    open,
    formValues,
    setFormValues,
    setSnackbarProps,
    isValidRange,
  } = props;

  const dispatch = useDispatch();

  const colorPicker = useRef();

  const isEditing = formValues.id;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const [isLastBucket, setIsLastBucket] = useState(false);

  const handleCheckedChange = (event) => {
    if (event.target.checked) {
      setFormValues({
        ...formValues,
        upperLimit: null,
      });
    }
    setIsLastBucket(event.target.checked);
  };

  const checkPrecision = (value) => {
    if (value && value.includes(".")) {
      const afterDecimalCount = value.split(".")[1].length;
      if (afterDecimalCount > 1) {
        return false;
      }
    }
    return true;
  };

  const handleAddBucket = async () => {
    checkPrecision(formValues.lowerLimit);
    if (isValidRange(formValues, false)) {
      if (
        checkPrecision(formValues.lowerLimit) &&
        checkPrecision(formValues.upperLimit)
      ) {
        await dispatch(addDeltaBucketAsync({ ...formValues }))
          .unwrap()
          .then(() => {
            setSnackbarProps({
              open: true,
              severity: "success",
              msg: "Added delta bin successfully",
            });
            toggleOpen();
          })
          .catch((err) => {
            setSnackbarProps({
              open: true,
              severity: "error",
              msg: "Failed to add delta bin, Try again!",
            });
          });
      } else {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Please enter 1 decimal precision for limit values",
        });
      }
    } else {
      setSnackbarProps({
        open: true,
        severity: "error",
        msg: "The range already exists",
      });
    }

    setIsLastBucket(false);
  };

  const handleUpdateBucket = async () => {
    if (isValidRange(formValues, true)) {
      if (
        checkPrecision(formValues.lowerLimit) &&
        checkPrecision(formValues.upperLimit)
      ) {
        await dispatch(updateDeltaBucketAsync({ ...formValues }))
          .unwrap()
          .then(() => {
            setSnackbarProps({
              open: true,
              severity: "success",
              msg: "Updated delta bin successfully",
            });
            toggleOpen();
          })
          .catch((err) => {
            setSnackbarProps({
              open: true,
              severity: "error",
              msg: "Failed to update delta bin, Try again!",
            });
          });
      } else {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Please enter 1 decimal precision for limit values",
        });
      }
    } else {
      setSnackbarProps({
        open: true,
        severity: "error",
        msg: "Range Exists",
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={toggleOpen} sx={{ height: "100%" }}>
        <DialogTitle>{isEditing ? "Update" : "Add"} Delta Bucket</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              size="small"
              label="Lower Limit"
              name="lowerLimit"
              onChange={handleInputChange}
              value={formValues.lowerLimit}
              type="number"
              sx={{ mb: 2 }}
            />
            <FormGroup sx={{ mb: 2, ml: 1, alignSelf: "flex-start" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLastBucket}
                    onChange={handleCheckedChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Is Last Bin"
              />
            </FormGroup>
            {!isLastBucket && (
              <TextField
                size="small"
                label="Upper Limit"
                name="upperLimit"
                onChange={handleInputChange}
                value={formValues.upperLimit}
                type="number"
                sx={{ mb: 2 }}
              />
            )}

            <TextField
              size="small"
              label="Keyword"
              name="keyword"
              onChange={handleInputChange}
              value={formValues.keyword}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mt: 3,
              }}
            >
              <Typography>Color</Typography>
              <Box
                component="input"
                type="color"
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
                ref={colorPicker}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Cancel</Button>
          <Button onClick={isEditing ? handleUpdateBucket : handleAddBucket}>
            {isEditing ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
